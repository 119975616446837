import React from "react";
import "../../App.css";

const Faq = () => {
  return (
    <div className="faq-container">
      <h1>PREGUNTAS FRECUENTES</h1>
      <p>
        <i>
          Sobre la creación de una asociación sin fines de lucro para beneficio
          de la comunidad de Villas los Altos de Boquete.
        </i>
      </p>
      <h1>ASPECTOS GENERALES</h1>
      <br />
      <h3>1. ¿Es lo mismo una asociación, una organización y una ONG?</h3>
      <p>
        Una organización es un término amplio que se refiere a cualquier entidad
        estructurada creada para alcanzar objetivos específicos. Una asociación
        es un tipo específico de organización formada por un grupo de personas
        que se reúnen con un fin común. Un ejemplo de una asociación sería un
        grupo de vecinos que se organiza para mejorar su comunidad. Las ONG
        tienen un enfoque más amplio y pueden operar a nivel local, nacional o
        internacional.
      </p>
      <h3>2. ¿Qué es una asociación sin fines de lucro?</h3>
      <p>
        Es una entidad cuyo fin no es la persecución de un beneficio económico
        para sus miembros, sino que principalmente persigue una finalidad
        social, cultural, deportiva y comunitaria para el progreso, desarrollo o
        bien común de la comunidad.
      </p>
      <h3>
        3. ¿Una asociación sin fines de lucro es lo mismo que una personería
        jurídica?
      </h3>
      <p>
        Una personería jurídica (P.J.) es el reconocimiento legal necesario para
        que cualquier entidad, incluida una asociación sin fines de lucro, pueda
        operar formalmente y tener derechos y obligaciones legales. En otras
        palabras, una P.J. es un estatus legal que una asociación pueda tener
        mientras que una asociación sin fines de lucro describe la naturaleza y
        el propósito de la asociación.
      </p>
      <h3>
        4. ¿Cuál es la diferencia entre una asociación sin fines de lucro y
        cualquier otro tipo de organización?
      </h3>
      <p>
        Los bienes económicos que se obtengan en una asociación sin fines de
        lucro se reinvierten en la propia asociación para cumplir sus funciones
        y objetivos, es importante resaltar que cualquier ganancia que se
        obtenga No se puede distribuir entre sus miembros, como es en el caso de
        otros tipos de organizaciones.
      </p>
      <h3>
        5. ¿Qué está permitido y qué está prohibido hacer con una asociación sin
        fines de lucro?
      </h3>
      <p>
        Existen ciertos deberes y derechos que se deben seguir en una asociación
        sin fines de lucro de acuerdo con su naturaleza y regulación legal.
      </p>
      <p>
        Algunos ejemplos de lo que está permitido hacer con una asociación sin
        fines de lucro:
      </p>
      <div className="asociacion">
        <ul>
          <li>
            Realizar actividades y proyectos alineados con la misión y objetivos
            de la asociación.
          </li>
          <li>
            Recaudar fondos ya sea mediante la organización de eventos,
            donaciones, subvenciones, vender productos o servicios siempre y
            cuando los ingresos se destinen a la causa de la asociación.
          </li>
          <li>
            Desarrollar programas y servicios que beneficien directamente a la
            comunidad o grupo objetivo al que se dirigen.
          </li>
          <li>
            Establecer alianzas y colaboraciones con otras organizaciones sin
            fines de lucro, gobiernos y empresas para proyectos comunes.
          </li>
        </ul>
      </div>
      <p>
        Algunos ejemplos de lo que está prohibido y NO se puede hacer con una
        asociación sin fines de lucro:
      </p>
      <div className="asociacion">
        <ul>
          <li>
            Distribuir ganancias o excedentes entre los miembros o junta
            directiva de la asociación. Todos los ingresos deben reinvertirse en
            la misión de la asociación.
          </li>
          <li>
            Apoyar o financiar a partidos políticos o candidatos a cargos
            públicos.
          </li>
          <li>
            Realizar cualquier tipo de actividades ilegales, inmorales o que
            vayan en contra de los estatutos y regulaciones de la asociación.
          </li>
          <li>
            Faltar a la transparencia y rendición de cuentas, ocultar cualquier
            tipo de información financiera o de gestión a los miembros, donantes
            y autoridades. Es de carácter obligatorio mantener una contabilidad
            clara y realizar auditorías cuando sea necesario.
          </li>
          <li>
            Utilizar los activos y recursos de la asociación para beneficio
            personal de los miembros o junta directiva.
          </li>
        </ul>
      </div>
      <h3>
        6. ¿Quién toma las decisiones en una asociación sin fines de lucro?
      </h3>
      <p>
        Dependiendo de los estatutos, la toma de decisiones puede recaer en la
        Junta Directiva, o por medio de una asamblea general de todos los
        miembros.
      </p>
      <p>
        De acuerdo a la naturaleza de la peticion, la toma de decisiones recae
        en:
      </p>
      <div className="asociacion">
        <ul>
          <li>
            <b>Junta Directiva</b>: Este es el órgano principal de toma de
            decisiones. La junta directiva está compuesta por miembros que son
            elegidos o designados según los estatutos de la organización.
          </li>
          <li>
            <b>Miembros del Comité</b>: Se encargan de áreas específicas (Comité
            Etapa 1, Comité Etapa 2, Comité de limpieza, Comité Cultural, etc.)
            y hacen recomendaciones a la junta directiva.
          </li>
          <li>
            <b>Miembros de la asociación</b>: Los miembros tienen derecho a
            votar sobre ciertos asuntos importantes, como la elección de los
            miembros de la junta directiva o cambios en los estatutos.
          </li>
        </ul>
      </div>
      <br /> <br />
      <h1>SOBRE LOS ESTATUTOS</h1>
      <br />
      <h3>1. ¿Qué son los estatutos?</h3>
      <p>
        Los estatutos establecen las normas y reglas bajo las cuales se regirá
        la organización. Definen la estructura, propósito y procedimientos de
        una asociación (este caso sin fines de lucro), incluyendo la misión,
        objetivos y proporciona el marco para la toma de decisiones y la
        administración diaria.
      </p>
      <h3>2. ¿Qué comprenden los estatutos de una asociación?</h3>
      <p>
        Los estatutos de una asociación comprenden su estructura, funcionamiento
        y administración. Los elementos típicos que podemos encontrar en los
        estatutos de una asociación sin fines de lucro serían los siguientes:
      </p>
      <div className="estatutos">
        <ul>
          <li>
            Nombre y Domicilio
            <ul>
              <li>Nombre legal de la asociación</li>
              <li>Domicilio</li>
            </ul>
          </li>
          <li>
            Objetivos y Fines
            <ul>
              <li>Misión</li>
              <li>Objetivos</li>
              <li>Actividades</li>
            </ul>
          </li>
          <li>
            Miembros
            <ul>
              <li>Clases de Miembros</li>
              <li>Derechos y deberes</li>
              <li>Admisión y Expulsión</li>
            </ul>
          </li>
          <li>
            Gobierno y Administración
            <ul>
              <li>Asamblea General</li>
              <li>Junta Directiva</li>
              <li>Comités</li>
            </ul>
          </li>
          <li>
            Reuniones
            <ul>
              <li>Convocatoria</li>
              <li>Quórum y Mayorías</li>
              {/* <ul>
                  <li>Quórum</li>
                  <li>Mayorías</li>
                </ul> */}
              <li>Frecuencia de Reuniones</li>
            </ul>
          </li>
          <li>
            Patrimonio y Recursos
            <ul>
              <li>Fuentes de Ingresos</li>
              <li>Administración de Recursos</li>
              <li>Presupuesto y Contabilidad</li>
            </ul>
          </li>
          <li>
            Modificación de Estatutos
            <ul>
              <li>Procedimiento</li>
              <li>Quórum y Mayorías Requeridas</li>
            </ul>
          </li>
          <li>
            Disposiciones Generales y Transitorias
            <ul>
              <li>Normas Adicionales</li>
              <li>Normas Transitorias</li>
            </ul>
          </li>
          <li>
            Disolución
            <ul>
              <li>Causas</li>
              <li>Procedimiento</li>
            </ul>
          </li>
        </ul>
      </div>
      <br />
      <h3>3. ¿Se pueden modificar los estatutos?</h3>
      <p>
        Sí, siempre y cuando sea antes de empezar el trámite legal para crear la
        asociación. Una vez se inicie el trámite legal, no se podrá modificar
        hasta que se complete el trámite y dependiendo del procedimiento para
        modificación de los estatutos (que es el tiempo de espera entre la
        creación / última modificación del estatuto) se podrá realizar la
        modificación sugerida. El tiempo para las modificaciones de los
        estatutos se definen previamente en los estatutos iniciales en la
        conformación de la asociación.
      </p>
      <br /> <br />
      <h1>SOBRE PREGUNTAS VECINALES</h1>
      <br />
      <h3>
        1. He escuchado: "Me pueden quitar mi casa y/o no podré entrar a mi
        casa” en caso de que se cree la asociación.
      </h3>
      <p>
        La propiedad privada de una persona no está relacionada con la creación
        o las actividades de una asociación sin fines de lucro. La asociación no
        tiene derechos ni autoridad legal sobre las propiedades personales de
        sus miembros o de cualquier otra persona. Recordemos que la naturaleza
        de la asociación se centra en proyectos y actividades alineadas con su
        misión y objetivos, como organizar eventos comunitarios o recaudar
        fondos para causas específicas. No tiene el propósito de poseer ni
        administrar propiedades privadas de individuos.
      </p>
      <h3>
        2. He escuchado: “Que pueden solicitar préstamos y si no lo pagan
        quedamos todos asumiendo esa deuda”.
      </h3>
      <p>
        La responsabilidad financiera de una asociación sin fines de lucro está
        limitada a los activos y recursos de la propia asociación. Los estatutos
        de la asociación son los que dictan las reglas y procedimientos para el
        manejo de las finanzas, incluyendo la administración de los recursos, la
        elaboración de presupuestos, la rendición de cuentas y la auditoría. Los
        miembros de la asociación no son responsables de las deudas o
        compromisos financieros de la asociación. La asociación no puede
        solicitar préstamos o adquirir deudas sin el consentimiento de sus
        miembros y sin seguir los procedimientos establecidos en sus estatutos y
        regulaciones legales, es decir, si los estatutos no permiten obtener un
        préstamo, la asociación no podría adquirir uno hasta que los estatutos
        cambien para que se pueda obtener un préstamo.
      </p>
      <h3>
        3. No estoy de acuerdo con la participación de algún vecino en la Junta
        Directiva.
      </h3>
      <p>
        La Junta directiva será escogida por las personas que quieran ser parte
        de la asociación en un proceso completamente democrático donde cualquier
        interesado también puede postularse para ser miembro de la junta
        directiva. La votación será regida por los estatutos que existan en ese
        momento.
      </p>
      <h3>
        4. Si no quiero ser parte de la asociación, ¿me puede obligar a
        participar de la misma?
      </h3>
      <p>
        La participación en una asociación es completamente voluntaria y está
        basada en el interés y el compromiso de los individuos con los objetivos
        y la misión de la organización sin ningún tipo de repercusión. Si usted
        decide no unirse, no está obligado a participar en las reuniones,
        decisiones y actividades de la asociación. Sin embargo, aún puede
        beneficiarse de los proyectos y actividades que realizamos en la
        comunidad, aunque no tendrá ni voz ni voto para la toma de decisiones
        dentro de la asociación.
      </p>
      <h3>
        5. He escuchado “Que es ilegal crear este tipo de asociación sin el
        permiso de la mayoría de los residentes, por lo que podemos impugnar y/o
        demandar si hacen la asociación”.
      </h3>
      <p>
        La formación de una asociación sin fines de lucro debe ser un proceso
        inclusivo y transparente, respetando los derechos de todos los
        involucrados. Si usted tiene preocupaciones sobre este proceso, le
        recomendamos participar activamente en las futuras reuniones
        informativas y asambleas, expresar sus dudas y, si es necesario, buscar
        asesoría legal para proteger sus derechos. La colaboración y el diálogo
        son esenciales para asegurar que la creación de la asociación se realice
        de manera justa y democrática.
      </p>
    </div>
  );
};

export default Faq;

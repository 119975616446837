import React from "react";
import Footer from "../Footer";
import "../../App.css";

export default function Noticias() {
  return (
    <>
      <h1 className="contacto">Contacto</h1>
      <Footer />
    </>
  );
}

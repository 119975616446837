import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

function Cards() {
  return (
    <div className="cards">
      <h1>NOTICIAS</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/img-10.jpg"
              text="En dias pasados se realizo la limpieza en etapa 1..."
              label="Junio 14, 2024"
              path="/images/img-10.jpg"
            />
            <CardItem
              src="images/img-11.jpg"
              text="El dia 1 de Junio de 2024 se realizo la limpieza..."
              label="Junio 1, 2024"
              path="/images/img-11.jpg"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="images/img-12.jpg"
              text="Por segunda semana consecutiva se realizo la limpieza..."
              label="Mayo 15, 2024"
              path="/images/img-12.jpg"
            />
            <CardItem
              src="images/img-16.jpg"
              text="Gracias a la donacion desinteresada de los vecinos de la comunidad, se realizo la limpieza..."
              label="Mayo 1, 2024"
              path="/images/img-16.jpg"
            />
            <CardItem
              src="images/img-17.jpg"
              text="Los vecinos de etapa 1 realizaron la limpieza del parque..."
              label="Abril 18, 2024"
              path="images/img-17.jpg"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;

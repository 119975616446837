import React from "react";
import "../../App.css";
import Footer from "../Footer";
import Faq from "./Faq";

export default function Preguntas() {
  return (
    <>
      <Faq />
      <Footer />
    </>
  );
}

import React from "react";
import Footer from "../Footer";
import Cards from "../Cards";
import "../../App.css";

export default function Noticias() {
  return (
    <>
      {/* <h1 className="noticias">Noticias</h1> */}
      <Cards />
      <Footer />
    </>
  );
}
